<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t("departments") }}
      </v-card-title>
      <v-card-text>
        <v-col cols="12" class="text-end">
          <v-btn
            @click="showEditDialog(null)"
            data-testid="departmentListNewDepartment"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("newDepartment") }}
          </v-btn>
        </v-col>
        <v-text-field
          :label="$t('search')"
          v-model="search"
          data-testid="departmentListSearch"
        />
        <DataTable
          :headers="headers"
          :items="departments"
          :search="search"
          :custom-filter="filterList"
          data-testid="departmentList"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              @click="showEditDialog(item)"
              data-testid="departmentListEdit"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="validateDelete(item.AvdelingId)"
              class="ml-4"
              data-testid="departmentListDelete"
            >
              mdi-delete
            </v-icon>
          </template>
        </DataTable>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogDelete" :max-width="dialogMaxWidth">
      <UserConfirm
        :text="$t('deleteDepartment?')"
        v-on:cancel="dialogDelete = false"
        v-on:ok="
          deleteDepartment();
          dialogDelete = false;
        "
      />
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      v-if="dialogEdit"
      :max-width="dialogMaxWidth"
    >
      <v-form
        @submit.prevent="
          save();
          dialogEdit = false;
        "
        autocomplete="off"
      >
        <v-card>
          <v-card-title>
            {{
              selectedDepartmentID ? $t("editDepartment") : $t("newDepartment")
            }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="nameEdited"
              :label="$t('name')"
              outlined
              autofocus
              maxlength="50"
              :rules="requiredFieldRule"
              data-testid="departmentListName"
            />
            <SmsSender
              :init-value="smsSenderEdited"
              v-on:change="setSmsSender"
              :optional="true"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="dialogEdit = false"
                data-testid="departmentListCancel"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                type="submit"
                class="primary"
                data-testid="departmentListSave"
                :disabled="!formValid"
                >{{ $t("ok") }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogCannotDelete" :max-width="dialogMaxWidth">
      <UserError v-on:ok="dialogCannotDelete = false">
        <template v-slot:text>
          {{ $t("cannotDeleteSelectedDepartment") }}
        </template>
      </UserError>
    </v-dialog>

    <v-dialog v-model="dialogInUse" :max-width="dialogMaxWidth">
      <UserError v-on:ok="dialogInUse = false">
        <template v-slot:text>
          {{ $t("cannotDeleteDepartmentInUse") }}
        </template>
      </UserError>
    </v-dialog>
  </div>
</template>

<script>
import apiDepartment from "@/api/v24/api.department";
import UserConfirm from "@/components/misc/UserConfirm";
import config from "@/config/config";
import SmsSender from "@/components/alert/SmsSender.vue";
import search from "@/util/search";
import formValidationRules from "../../util/formValidationRules";
import UserError from "@/components/misc/UserError.vue";
import user from "@/util/user";
import store from "@/store";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "DepartmentList",
  components: { DataTable, UserError, SmsSender, UserConfirm },

  computed: {
    headers() {
      return [
        { text: this.$t("name"), value: "Navn" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ];
    },
    formValid() {
      return (
        !!this.nameEdited && this.smsSenderValid && !!this.nameEdited.trim()
      );
    },
    departments() {
      return store.state.departments;
    }
  },

  data: () => ({
    dialogDelete: false,
    dialogEdit: false,
    dialogInUse: false,
    dialogMaxWidth: config.dialogWith.medium,
    dialogCannotDelete: false,
    selectedDepartmentID: null,
    nameEdited: "",
    smsSenderEdited: "",
    search: "",
    smsSenderValid: true,
    requiredFieldRule: formValidationRules.requiredRule
  }),

  created() {
    this.update();
  },

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },

    update() {
      store.dispatch("updateDepartments");
      // Also update user profile state since _adminDepartments in user
      // profile may change
      store.dispatch("updateUserProfile", true);
    },

    setSmsSender(smsSender) {
      this.smsSenderEdited = smsSender.text;
      this.smsSenderValid = smsSender.isValid;
    },

    // Set departmentId to null to create new department
    showEditDialog(department) {
      if (department) {
        this.selectedDepartmentID = department.AvdelingId;
        this.nameEdited = department.Navn;
        this.smsSenderEdited = department.SmsAvsender;
      } else {
        this.selectedDepartmentID = null;
        this.nameEdited = "";
        this.smsSenderEdited = "";
      }
      this.dialogEdit = true;
    },

    save() {
      let promise;
      if (this.selectedDepartmentID) {
        promise = apiDepartment.edit(
          this.selectedDepartmentID,
          this.nameEdited,
          this.smsSenderEdited
        );
      } else {
        promise = apiDepartment.create(this.nameEdited, this.smsSenderEdited);
      }
      promise.then(() => {
        this.nameEdited = "";
        this.update();
      });
    },

    validateDelete(departmentId) {
      if (departmentId === user.getSelectedDepartment()) {
        this.dialogCannotDelete = true;
        return;
      }
      this.confirmDelete(departmentId);
    },

    confirmDelete(departmentId) {
      this.selectedDepartmentID = departmentId;
      this.dialogDelete = true;
    },

    deleteDepartment() {
      apiDepartment
        .delete(this.selectedDepartmentID)
        .then(() => {
          this.update();
        })
        .catch(err => {
          if (err._inUse) {
            this.dialogInUse = true;
          } else {
            alert(err.message || this.$t("unknownError"));
          }
        });
    }
  }
};
</script>
