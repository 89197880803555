<template>
  <v-container id="content">
    <DepartmentList />
  </v-container>
</template>

<script>
import DepartmentList from "@/components/departments/DepartmentList";
export default {
  name: "Departments",
  components: { DepartmentList }
};
</script>
<style scoped>
#content {
  max-width: 900px;
}
</style>